<template>
  <div>
    <Header />
    <div class="dash-body">
      <div class="dash-header">
        <div class="dash-caption reports-page">
          {{ $t('REPORTS_REPORT') }}
          <button class="btn-st-tr reports-b">{{ $t('REPORTS_MANAGE') }}</button>
        </div>
        <div class="dash-options">
          <calendar :callback="setPage" />
        </div>
      </div>
      <div class="p-list reports-tab">
        <table>
          <tbody>
            <tr :key="index" v-for="(report, index) in getReports">
              <td>{{ report.type }}</td>
              <td>
                {{ $t('REPORTS_LIST') }} 
                <a href="/">{{ report.names }}</a> {{ $t('REPORTS_LIST_PRD') }} 
                <b
                  >{{ moment(report.from).format("YYYY-MM-DD") }} -
                  {{ moment(report.to).format("YYYY-MM-DD") }}</b
                >
                {{ $t('REPORTS_WAS') }} 
                <b>{{ moment(report.date).format("YYYY-MM-DD, HH:mm") }}</b>
              </td>
              <td
                class="reports-export"
                @click="download(report.id, report.type)"
              >
                {{ $t('REPORTS_DOWNLD') }} 
              </td>
              <td>
                <span
                  class="close"
                  @click="DELETE_REPORT({ id: report.id, type: report.type })"
                  ><i class="bi bi-x-lg"></i></span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Calendar from "../components/Calendar";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  REPORTS_GET,
  REPORTS_DOWNLOAD,
  AUTOREPORTS_GET,
  DELETE_AUTOREPORT,
  DELETE_REPORT,
} from "../store/actions/reports";

export default {
  name: "ReportsList",
  components: {
    Header,
    Calendar,
  },
  computed: {
    ...mapGetters(["getSelectedPages", "getPeriod", "getReports"]),
  },
  methods: {
    ...mapActions([
      REPORTS_GET,
      AUTOREPORTS_GET,
      REPORTS_DOWNLOAD,
      DELETE_REPORT,
    ]),
    setPage() {
      this.REPORTS_GET();
      this.AUTOREPORTS_GET();
    },
    download: function (hashId, type) {
      this.$store.dispatch(REPORTS_DOWNLOAD, { hashId, type }).then((response) => {
        if (response.status != 200) return;

        const fileURL = window.URL.createObjectURL(response.data);
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `file.${type}`);
        fileLink.click();
      });
    },
  },
  mounted() {
    this.setPage();
  },
};
</script>

<style>
</style>